<template>
  <div>
    <b-modal id="LevelInfoModal" v-model="modalShow" @hide="statChange" footer-class="justify-content-center" size="md"
      hide-footer no-close-on-backdrop no-close-on-esc centered scrollable>

      <template #modal-header="{ close }">
        <h5 class="modal-title">레벨 정보</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

      <div
        :style="{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '10px 0', marginBottom: '10px', justifyContent: 'center', alignItems: 'center', width: '100%', borderRadius: '20px', border: '1px #032D98 solid' }">
        <div v-for="(item, index) in levelState" :key="index" :style="{ width: '100%' }">
          <div :style="{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }">
            <div :style="{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
              <span>Lv.{{ item.level }}</span>
            </div>
            <div :style="{ display: 'flex', flexDirection: 'column' }">
              <span :style="{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '0.8rem' }">누적
                {{
                  item.kdia_charge.toLocaleString() }}KDIA, 관리자 승인후 레벨업</span>
              <span :style="{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '0.8rem' }">1일
                {{ item.posting_max
                }} 포스팅, {{ item.mining_value.toLocaleString() }}TIPS 채굴</span>
            </div>
          </div>
        </div>
      </div>

      <div
        :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }">
        <div @click="childStatChange"
          :style="{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', background: '#032D98', borderRadius: '4px', padding: '5px 20px', margin: '5px 0' }">
          <div :style="{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }">
            <span :style="{ color: '#fff', fontWeight: 600, fontSize: '16px' }">KDIA 입금 신청</span>
            <span :style="{ color: '#95ACE8', fontWeight: 500, fontSize: '12px' }">외부지갑 연결하여 관리자에게 전송(트론링크 등)</span>
          </div>
          <span :style="{ color: '#fff', fontWeight: 800, fontSize: '20px' }"><i class="ri-arrow-right-s-line"></i></span>
        </div>
        <span @click="goPath('user.kdiaDList')"
          :style="{ color: '#000', fontWeight: 500, fontSize: '14px', cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }">
          KDIA 입금 내역보기
        </span>
      </div>
      <template #modal-footer></template>
    </b-modal>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LevelInfoModal',
  props: [
    'value'
  ],
  data () {
    return {
      modalShow: this.value,
      matic: '',
      polyt: '0',
      address: '',
      reward: this.total_reward
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      levelState: 'Setting/levelState',
      userState: 'Auth/userState',
      walletState: 'WalletConnect/walletState',
      providerState: 'WalletConnect/providerState'
    })
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  },
  methods: {
    ...mapActions({
      updateCoin: 'Auth/doCheering',
      setAmount: 'WalletConnect/setAmount',
      setKlipQR: 'WalletList/setKlipQR'
    }),
    goPath (to) {
      this.$router.push({ name: to })
    },
    statChange () {
      this.$emit('statChange')
    },
    childStatChange () {
      this.$emit('childStatChange')
    },
    cancelPurchase (cancel) {
      this.matic = ''
      this.polyt = ''
      this.address = ''
      cancel()
    }
  }
}
</script>
