<template>
  <div>
    <b-modal id="RefModal"
             v-model="modalShow"
             @hide="statChange"
             footer-class="justify-content-center"
             size="md"
             hide-footer
             no-close-on-backdrop
             no-close-on-esc
             centered
             scrollable>

      <template #modal-header="{ close }">
        <h5 class="modal-title">나를 추천한 회원</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

      <!-- <div class="my-3" v-for="i in this.items" :key="i" :style="{display: 'flex', justifyContent:'space-between', width: '100%'}">
        <span>{{i.email}}</span>
        <span>{{i.name}}</span>
        <span>{{getTime(i.referralTime)}}</span>
    </div> -->

    <b-table
        id="list-table"
        responsive
        :small="true"
        :items="this.items"
        :fields=ListFields
        :fixed="true"
        :per-page="this.items.length"
        :no-border-collapse="true"
        :tbody-tr-class="rowClass"
        >

      <!-- 이메일 -->
      <template #cell(email)="data">
        <span :style="{ fontSize: '0.8rem', color:'#3F3F3F', fontWeight: 500 }">
          {{ data.value }}
          <!-- {{ data.value.substr(0, data.value.length - 2) }}** -->
        </span>
      </template>

      <!-- 이름 -->
      <template #cell(name)="data">
        <span :style="{ fontSize: '0.8rem', color:'#3F3F3F', fontWeight: 500 }">
          {{ data.value }}
          <!-- {{ data.value.substr(0, data.value.length - 2) }}** -->
        </span>
      </template>

      <!-- 날짜 변경 -->
      <template #cell(referralTime)="data">
        <span :style="{ fontSize: '0.7rem', color: '#7E7E7E', fontWeight: 500 }">
          {{ getTime(data.value) }}
        </span>
      </template>
    </b-table>

    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'RefModal',
  props: [
    'value', 'items'
  ],
  methods: {
    getTime (timestamp) {
      if (timestamp === null) {
        return ''
      }
      const date = new Date(timestamp)

      const sYear = date.getFullYear()
      let sMonth = date.getMonth() + 1
      let sDate = date.getDate()

      sMonth = sMonth > 9 ? sMonth : '0' + sMonth
      sDate = sDate > 9 ? sDate : '0' + sDate
      // return `${sYear}-${sMonth}-${sDate} ${sHours}:${sMinutes}:${sSeconds}`;
      return `${sYear}-${sMonth}-${sDate}`
    }
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  },
  data () {
    return {
      modalShow: this.value,
      ListFields: [
        {
          key: 'email',
          label: '이메일',
          thStyle: { width: '40%', border: '0px', height: '0px !important', background: this.$props.color },
          tdClass: 'text-start align-middle text-truncate'
        },
        {
          key: 'name',
          label: '닉네임',
          thStyle: { border: '0px', height: '0px !important', background: this.$props.color },
          thClass: 'text-end align-middle text-truncate',
          tdClass: 'text-end align-middle text-truncate'
        },
        {
          key: 'referralTime',
          label: '-',
          thStyle: { border: '0px', height: '0px !important', background: this.$props.color },
          thClass: 'text-center align-middle text-truncate',
          tdClass: 'text-center align-middle text-truncate'
        }
      ]
    }
  }
}
</script>
