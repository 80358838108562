<template>
  <div>
    <b-modal id="LevelModal"
             v-model="modalShow"
             @hide="statChange"
             footer-class="justify-content-center"
             size="md"
             no-close-on-backdrop
             no-close-on-esc
             centered
             scrollable>

      <template #modal-header="{ close }">
        <h5 class="modal-title">입금 신청</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

        <p>거래서에서 입급주소로 보낸 KDIA, 주소를 입력해주세요.</p>
        <p>입금 주소: 관리자 지갑</p>
        <template>
        <b-form class="comment-text d-flex align-items-center" action="javascript:void(0);">
          <b-form-input :placeholder="'입금한 지갑주소를 입력해주세요.'" v-model="address" class="rounded" />
        </b-form>
        <b-form class="comment-text d-flex align-items-center" action="javascript:void(0);">
          <b-form-input :placeholder=" '입금한 KDIA를 입력해주세요.'" type="number" v-model="matic" class="rounded" />
        </b-form>
      </template>
      <template #modal-footer="{ cancel, ok }">
        <b-button size="sm" variant="danger" @click="cancelPurchase(cancel)">취소</b-button>
        <b-button size="sm" variant="success" @click="doPurchase(ok)">확인</b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>

import wallet from '../../../services/wallet/wallet'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LevelModal',
  props: [
    'value'
  ],
  data () {
    return {
      modalShow: this.value,
      matic: '',
      polyt: '0',
      address: '',
      reward: this.total_reward
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      walletState: 'WalletConnect/walletState',
      providerState: 'WalletConnect/providerState'
    })
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  },
  methods: {
    async doPurchase (ok) {
      if (this.address === '') {
        alert('입금한 주소를 입력해주세요.')
        return
      }
      if (this.matic === '') {
        alert('입금한 KDIA 입력해주세요.')
        return
      }
      wallet.askingPurchase(this.address, this.matic, 2).then(res => {
        if (res.data.resultCode === 'false') {
          alert('관리자에게 문의해주세요.')
          ok()
          return
        }
        this.address = ''
        this.matic = ''
        alert('입금 요청이 완료되었습니다.')
        ok()
      })
    },
    ...mapActions({
      updateCoin: 'Auth/doCheering',
      setAmount: 'WalletConnect/setAmount',
      setKlipQR: 'WalletList/setKlipQR'
    }),
    statChange () {
      this.$emit('statChange')
    },
    cancelPurchase (cancel) {
      this.matic = ''
      this.polyt = ''
      this.address = ''
      cancel()
    }
  }
}
</script>
