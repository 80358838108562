<template>
  <div>
    <b-modal id="StakingModal"
             v-model="modalShow"
             @hide="statChange"
             footer-class="justify-content-center"
             size="md"
             no-close-on-backdrop
             no-close-on-esc
             centered
             scrollable>

      <template #modal-header="{ close }">
        <h5 class="modal-title">스테이킹</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

        <p>스테이킹 수량을 입력해주세요.<br />보유량: {{ this.userState.coin.toString() }}</p>
      <b-form class="comment-text d-flex align-items-center" action="javascript:void(0);">
        <b-form-input type="number" v-model="coin" class="rounded" />
        <div class="comment-attagement d-flex">
        </div>
        </b-form>

      <template #modal-footer="{ cancel, ok }">
        <b-button size="sm" variant="danger" @click="cancelStaking(cancel)">취소</b-button>
        <b-button size="sm" variant="success" @click="doStaking(ok)">확인</b-button>
      </template>

    </b-modal>
  </div>
</template>
<script>

import wallet from '../../../services/wallet/wallet'
import { mapActions, mapGetters } from 'vuex'
import Decimal from 'decimal.js'

export default {
  name: 'StakingModal',
  props: [
    'value'
  ],
  data () {
    return {
      modalShow: this.value,
      coin: ''
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      stakingState: 'Auth/stakingState',
      walletState: 'WalletConnect/walletState'
    })
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  },
  methods: {
    ...mapActions({
      setStaking: 'Auth/setStaking',
      updateCoin: 'Auth/doCheering'
    }),
    statChange () {
      this.$emit('statChange')
    },
    cancelStaking (cancel) {
      this.coin = ''
      cancel()
    },
    doStaking (ok) {
      if (this.coin === '') {
        alert('스테이킹 수량을 입력해주세요.')
        return
      }
      const stakingCoin = new Decimal(this.coin)
      if (stakingCoin < new Decimal(0)) {
        alert('0보다 작은값은 불가능합니다.')
        return
      }
      const ownCoin = new Decimal(this.userState.coin)
      if (stakingCoin.comparedTo(ownCoin) === 1) {
        alert('보유량보다 많은 스테이킹은 하실수 없습니다.')
        return
      }
      wallet.staking(this.coin).then(async res => {
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          this.coin = ''
          ok()
          return
        }
        this.coin = ''
        await this.setStaking([res.data.staking])
        // this.updateCoin(ownCoin - stakingCoin)
        this.$emit('showStaking', true)
        alert('스테이킹이 완료되었습니다.')
        ok()
      })
    }
  }
}
</script>
