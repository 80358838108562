<template>
  <div :style="{ display: 'flex' }">
    <StakingModal @statChange="setStakingModal" @showStaking="showStaking" :value="stakingModalShow" />
    <PurchaseInShopModal @statChange="setPurchaseInShopModalShow" :value="purchaseInShopModalShow" />
    <WithdrawModal @statChange="setWithdrawModalShow" :value="withdrawModalShow" />
    <WithdrawConnectModal @statChange="setWithdrawConnectModalShow" :value="withdrawConnectModalShow" />
    <LevelModal @statChange="setLevelModalShow" :value="levelModalShow" />
    <RefModal @statChange="setRefModalShow" :value="refModalShow"  :items="refItems"/>
    <LevelInfoModal @statChange="setLevelInfoModalShow" @childStatChange="setLevelModalShow"
      :value="levelInfoModalShow" />
    <KilpQRModal @statKilpModal="setKlipModal" :value="klipModalShow" :qr="klipQRState" />
    <SelectWalletModal @statKilpModal="setKlipModal" @setWalletModal="setWalletModal" @action="action"
      @setWallet="setWallet" :value="selectModalShow" />
    <PurchaseModal @statAmountChange="setModal" @statKilpModal="setKlipModal" :value="modalShow" :modalType="type"
      :wallet="wallet" :fromToken="selectedItem.from" :ratio="selectedItem.ratio" :toToken="selectedItem.symbol"
      :balance="walletState.amount" />
    <div
      :style="{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: '2vw' }">

      <!-- 회원 정보 -->
      <iq-card :style="{
        width: '380px',
        height: '730px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start',
        background: '#032D98',
        border: '1px #032D98 solid',
        padding: '20px',
        borderRadius: '4px',
        marginBottom: '0px'
      }">
        <div :style="{ width: '100%', display: 'flex', justifyContent: 'center'}">
          <span :style="{ color: '#fff', fontWeight: 700, fontSize: '20px', position: 'absolute', top: '30px'  }">회원 정보</span>
        </div>
        <div :style="{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative', height: '150px', alignItems:'end' }">
          <div :style="{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative',  height: '95px', alignItems: 'center' }">
            <i v-if="image === null" :style="{ color: '#fff', fontSize: '70px' }" class="ri-account-circle-line"></i>
            <img v-else :src="image" :style="{ width: '70px', borderRadius: '50%' }" />
          </div>
          <div
            :style="{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '10px', position: 'absolute', bottom: '0px', right: '0px' }">
            <form method="post" enctype="multipart/form-data">
              <div
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <label
                  :style="{ color: '#fff', fontWeight: 500, fontSize: '12px', margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }"
                  for="image">
                  수정
                </label>
              </div>
              <input :style="{ display: 'none' }" type="file" id="image" name="image" accept="image/*"
                @change="selectedImage" />
            </form>
            <div @click="editImageDo"
              :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
              <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                저장
              </span>
            </div>
          </div>
        </div>

        <div :style="{ width: '100%', backgroundColor: '#153CA0', height: '2px', margin: '10px 0', padding: 0 }" />
        <div
          :style="{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'start', justifyContent: 'space-between' }">
          <div :style="{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'start' }">
            <span :style="{ color: '#748BC6', fontWeight: 500, fontSize: '16px' }">회원 레벨</span>
            <span v-if="userState.level !== 99" :style="{ color: '#fff', fontWeight: 500, fontSize: '16px' }">Lv.{{
              userState.level }}</span>
            <span v-else :style="{ color: '#fff', fontWeight: 500, fontSize: '16px' }">유튜버</span>
          </div>
          <div :style="{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'end' }">
            <h3><i class="ri-question-line" :style="{ color: '#fff' }"  @click="setLevelInfoModalShow()"/></h3>
          </div>
        </div>
        <div :style="{ width: '100%', backgroundColor: '#153CA0', height: '2px', margin: '10px 0', padding: 0 }" />
        <div :style="{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'start' }">
          <span :style="{ color: '#748BC6', fontWeight: 500, fontSize: '16px' }">회원 이메일</span>
          <span :style="{ color: '#fff', fontWeight: 500, fontSize: '16px' }">{{ userState.account }}</span>
        </div>

        <div :style="{ width: '100%', backgroundColor: '#153CA0', height: '2px', margin: '10px 0', padding: 0 }" />

        <div v-if="!isPasswordOpen && !isReferralOpen"
          :style="{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'start' }">
          <div v-if="!isNameOpen"
            :style="{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'start', alignItems: 'start' }">
            <div :style="{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }">
              <span :style="{ color: '#748BC6', fontWeight: 500, fontSize: '16px' }">사용자 이름</span>
              <div @click="editNameOpen"
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                  수정
                </span>
              </div>
            </div>
            <span :style="{ color: '#fff', fontWeight: 500, fontSize: '16px' }">{{ userState.name }}</span>
          </div>

          <div v-else
            :style="{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }">
            <b-input v-model="name" :style="{ width: '100%' }" />
            <div
              :style="{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }">
              <div @click="editNameOpen"
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                  취소
                </span>
              </div>
              <div @click="editNameDo"
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                  저장
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- <div v-if="!(!isNameOpen && !isReferralOpen)" :style="{ width: '100%', backgroundColor: '#153CA0', height: '2px', margin: '10px 0', padding: 0 }" /> -->

        <div v-if="!isNameOpen && !isReferralOpen"
          :style="{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'start' }">
          <div v-if="!isPasswordOpen"
            :style="{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'start', alignItems: 'start' }">
            <div :style="{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }">
              <span :style="{ color: '#748BC6', fontWeight: 500, fontSize: '16px' }">비밀번호</span>
              <div @click="editPasswordOpen"
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                  수정
                </span>
              </div>
            </div>
            <span :style="{ color: '#fff', fontWeight: 500, fontSize: '16px' }">**********</span>
          </div>

          <div v-else
            :style="{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }">
            <b-input type="password" v-model="password" :placeholder="'변경할 비밀번호를 입력해주세요.'" :style="{ width: '100%' }" />
            <b-input type="password" v-model="checkPassword" :placeholder="'변경할 비밀번호를 한번더 입력해주세요.'"
              :style="{ width: '100%', marginTop: '10px' }" />
            <div
              :style="{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }">
              <div @click="editPasswordOpen"
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                  취소
                </span>
              </div>
              <div @click="editPasswordDo"
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                  저장
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- <div v-if="!(!isNameOpen && !isReferralOpen)" :style="{ width: '100%', backgroundColor: '#153CA0', height: '2px', margin: '10px 0', padding: 0 }" /> -->

        <div v-if="!isNameOpen && !isPasswordOpen"
          :style="{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'start' }">
          <div v-if="!isReferralOpen"
            :style="{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'start', alignItems: 'start' }">
            <div :style="{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }">
              <span :style="{ color: '#748BC6', fontWeight: 500, fontSize: '16px' }">추천인</span>
              <div  v-if="userState.referral === null" @click="setReferralOpen"
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <!-- TODO 추천인 보기 -->
                <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                  입력하기
                </span>
              </div>
            </div>
            <span v-if="userState.referral !== null" :style="{ color: '#fff', fontWeight: 500, fontSize: '16px' }">{{userState.referral.toEmail}}</span>
          </div>

          <div v-else
            :style="{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }">
            <b-input v-model="referral" :placeholder="'추천인 이메일을 입력해주세요.'" :style="{ width: '100%' }" />
            <div
              :style="{ display: 'flex', width: '50%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }">
              <!-- 추천인 입력 취소 -->
              <div @click="setReferralOpen"
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                  취소
                </span>
              </div>
              <!-- 추천인 입력 저장 -->
              <div @click="setReferralDo"
                :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 10px' }">
                <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
                  저장
                </span>
              </div>
            </div>
          </div>
        </div>

        <div :style="{position: 'absolute', bottom: '85px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '90%'}">
          <div @click="handleCopy"
          :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center',padding:'10px', width: '48%' }">
          <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
            추천인 링크 복사
          </span>
        </div>
        <div @click="setRefModalShow"
          :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center',  padding:'10px', width: '48%' }">
          <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
            나를 추천한 회원
          </span>
        </div>
        </div>
        <div v-if="!stakingOpen"
        :style="{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '20px'  }">
        <div @click="setStakingModal"
          :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50px' }">
          <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
            스테이킹 신청하기
          </span>
        </div>
      </div>
      <div v-else :style="{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: '20px' }">
        <div @click="stakingCancel"
          :style="{ cursor: 'pointer', border: '1px #fff solid', background: '#032D98 !important', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50px' }">
          <span :style="{ color: '#fff', fontWeight: 500, fontSize: '12px' }">
            스테이킹 해제하기
          </span>
        </div>
      </div>
      </iq-card>

      <!-- 코인 정보 -->
      <iq-card :style="{
        width: '380px',
        height: '730px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        padding: '20px',
        borderRadius: '4px',
        marginBottom: '0px'
      }">
        <div :style="{ width: '100%', display: 'flex', justifyContent: 'center' }">
          <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px', position: 'absolute', top: '30px' }">TIPS
            코인정보</span>
        </div>
        <div
          :style="{ border: '1px #032D98 solid', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '320px', padding: '10px 0', borderRadius: '20px' }">
          <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px' }">보유 수량</span>
          <span :style="{ color: '#032D98', fontWeight: 700, fontSize: '20px', margin: ' 0 15px  0' }">{{ this.stakingOpen
            ?
            ownCoin.toLocaleString() : userState.coin.toLocaleString() }}
            TIPS</span>

          <div
            :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '320px', margin: '15px 0 0' }">
            <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px' }">사용가능 수량</span>
            <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px', margin: ' 0 15px 0' }">
              {{ this.stakingOpen ? ableCoin.toLocaleString() : userState.coin.toLocaleString() }} TIPS</span>
            <div
              :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#F2F2F2', width: '30px', height: '30px', borderRadius: '50%', margin: '15px 0' }">
              <span :style="{ fontSize: '25px', fontWeight: 700, color: '#032D98' }">+</span>
            </div>
            <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px', margin: '0 15px 0' }">락 수량</span>
            <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px' }">{{ this.stakingOpen ?
              stakingCoin.toLocaleString() : 0 }}
              TIPS</span>
          </div>
        </div>
        <div
          :style="{ border: '1px #032D98 solid', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '320px', marginTop: '30px', padding: '10px 0', borderRadius: '20px' }">
          <div
            :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '320px', margin: '15px 0 0', gap: '15px' }">
            <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }">
              <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px' }">누적 채굴량</span>
              <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px', margin: ' 0 15px 0' }">
                {{ mining.total.toLocaleString() }} TIPS</span>
            </div>
            <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }">
              <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px' }">최근 30일 누적 채굴량</span>
              <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px', margin: ' 0 15px 0' }">
                {{ mining.month.toLocaleString() }} TIPS</span>
            </div>
            <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }">
              <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px', margin: '0 15px 0' }">누적 보상 채굴량</span>
              <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px' }">
                {{ mining.referral.toLocaleString() }} TIPS</span>
            </div>
          </div>
        </div>
          <span @click="goPath('user.useageList')"
            :style="{ position: 'absolute', bottom: '30px', color: '#000', fontWeight: 500, fontSize: '14px', cursor: 'pointer', textDecoration: 'underline' }">
            내역보기
          </span>
        <!-- <div :style="{ width: '100%', display: 'flex', justifyContent: 'center' }">
          <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px' }">TIPS 코인정보</span>
        </div>
        <div
          :style="{ border: '1px #032D98 solid', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '320px', height: '190px', marginTop: '30px' }">
          <img :src="tipsCoin" :style="{ width: '70px', height: '55px' }" />
          <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px', margin: '15px 0' }">보유 수량</span>
          <span :style="{ color: '#032D98', fontWeight: 700, fontSize: '20px' }">{{ this.stakingOpen ?
            ownCoin.toLocaleString() : userState.coin.toLocaleString() }}
            TIPS</span>
        </div>

        <div
          :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '320px', marginTop: '40px' }">
          <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px' }">사용가능 수량</span>
          <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px', margin: '15px 0' }">
            {{ this.stakingOpen ? ableCoin.toLocaleString() : userState.coin.toLocaleString() }} TIPS</span>
          <div
            :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#F2F2F2', width: '31px', height: '31px', borderRadius: '50%', margin: '30px 0' }">
            <span :style="{ fontSize: '24px', fontWeight: 700, color: '#032D98' }">+</span>
          </div>
          <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px', margin: '15px 0' }">락 수량</span>
          <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px' }">{{ this.stakingOpen ?
            stakingCoin.toLocaleString() : 0 }}
            TIPS</span>
        </div>
        <span @click="goPath('user.useageList')"
          :style="{ color: '#000', fontWeight: 500, fontSize: '14px', cursor: 'pointer', textDecoration: 'underline', position: 'absolute', bottom: '30px' }">
          사용 내역보기
        </span> -->
      </iq-card>

      <div
        :style="{ width: '380px', height: '730px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }">
        <!-- 스테이킹 정보 -->
        <iq-card :style="{
          width: '380px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          padding: '20px',
          borderRadius: '4px',
          marginBottom: '0px'
        }">
          <div
            :style="{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }">
            <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px' }">스테이킹 정보</span>
          </div>
          <div
            :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '320px', margin: '20px 0', gap: '10px' }">
            <div
              :style="{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '320px' }">
              <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px' }">스테이킹 시작일</span>
              <span :style="{ color: '#032D98', fontWeight: 600, fontSize: '16px' }"> {{ started }}</span>
            </div>
            <div
              :style="{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '320px' }">
              <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px' }">스테이킹 종료일</span>
              <span :style="{ color: '#032D98', fontWeight: 600, fontSize: '16px' }"> {{ expired }}</span>
            </div>
            <div
              :style="{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '320px' }">
              <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px' }">스테이킹 수량</span>
              <span :style="{ color: '#032D98', fontWeight: 600, fontSize: '16px' }"> {{ stakingCoin.toLocaleString() }}
                TIPS</span>
            </div>
            <div
              :style="{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '320px' }">
              <span :style="{ color: '#000', fontWeight: 500, fontSize: '16px' }">이자지급 누적수량 안내</span>
              <span :style="{ color: '#032D98', fontWeight: 600, fontSize: '16px' }"> {{ myTotalFee.toLocaleString() }}
                TIPS</span>
            </div>
          </div>
          <span @click="goPath('user.stakingList')"
            :style="{ cursor: 'pointer', color: '#F90303', fontWeight: 500, fontSize: '12px' }">
            *스테이킹 이자는 사용가능 수량으로 매일 전송됩니다. 더보기
          </span>

        </iq-card>

        <!-- 입출금 -->
        <iq-card :style="{
          width: '380px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff',
          padding: '20px',
          borderRadius: '4px',
          marginBottom: '0px'
        }">
          <div :style="{ width: '100%', display: 'flex', justifyContent: 'center' }">
            <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px' }">코인 입출금</span>
          </div>
          <div
            :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '320px', margin: '20px 0', gap: '10px' }">
            <div @click="setPurchaseInShopModalShow"
              :style="{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '320px', background: '#032D98', borderRadius: '4px', padding: '5px 20px' }">
              <div :style="{ display: 'flex', flexDirection: 'column' }">
                <span :style="{ color: '#fff', fontWeight: 600, fontSize: '16px' }">코인 입금 신청</span>
                <span :style="{ color: '#95ACE8', fontWeight: 500, fontSize: '12px' }">거래소에서 직접 보내기(관리자 확인 필요)</span>
              </div>
              <span :style="{ color: '#fff', fontWeight: 800, fontSize: '20px' }"><i
                  class="ri-arrow-right-s-line"></i></span>
            </div>
            <div @click="setWalletModal(true, tokens, 'charging')"
              :style="{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '320px', background: '#032D98', borderRadius: '4px', padding: '5px 20px' }">
              <div :style="{ display: 'flex', flexDirection: 'column' }">
                <span :style="{ color: '#fff', fontWeight: 600, fontSize: '16px' }">코인 입금 신청</span>
                <span :style="{ color: '#95ACE8', fontWeight: 500, fontSize: '12px' }">외부지갑 연결하여 자동 입금하기(메타마스크 등)</span>
              </div>
              <span :style="{ color: '#fff', fontWeight: 800, fontSize: '20px' }"><i
                  class="ri-arrow-right-s-line"></i></span>
            </div>
            <div @click="setWithdrawModalShow"
              :style="{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '320px', background: '#032D98', borderRadius: '4px', padding: '5px 20px' }">
              <div :style="{ display: 'flex', flexDirection: 'column' }">
                <span :style="{ color: '#fff', fontWeight: 600, fontSize: '16px' }">코인 출금 신청</span>
                <span :style="{ color: '#95ACE8', fontWeight: 500, fontSize: '12px' }">받을 지갑 주소를 입력하여 자동 출금하기</span>
              </div>
              <span :style="{ color: '#fff', fontWeight: 800, fontSize: '20px' }"><i
                  class="ri-arrow-right-s-line"></i></span>
            </div>
            <div @click="setWalletModal(true, tokens, 'withdraw')"
              :style="{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '320px', background: '#032D98', borderRadius: '4px', padding: '5px 20px' }">
              <div :style="{ display: 'flex', flexDirection: 'column' }">
                <span :style="{ color: '#fff', fontWeight: 600, fontSize: '16px' }">코인 출금 신청</span>
                <span :style="{ color: '#95ACE8', fontWeight: 500, fontSize: '12px' }">외부지갑 연결하여 자동 출금하기(메타마스크 등)</span>
              </div>
              <span :style="{ color: '#fff', fontWeight: 800, fontSize: '20px' }"><i
                  class="ri-arrow-right-s-line"></i></span>
            </div>
          </div>
          <span @click="goPath('user.dwList')"
            :style="{ color: '#000', fontWeight: 500, fontSize: '14px', cursor: 'pointer', textDecoration: 'underline' }">
            입출금 내역보기
          </span>
        </iq-card>
      </div>

      <!-- 레벨 정보 -->
      <!-- <iq-card :style="{
        width: '380px',
        height: '730px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        padding: '20px',
        borderRadius: '4px',
        marginBottom: '0px'
      }">
        <div :style="{ width: '100%', display: 'flex', justifyContent: 'center' }">
          <span :style="{ color: '#000', fontWeight: 700, fontSize: '20px' }">레벨 정보</span>
        </div>
        <div
          :style="{ border: '1px #032D98 solid', display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center', alignItems: 'center', width: '340px', height: '800px', margin: '5px 0' }">
          <div v-for="(item, index) in levelState" :key="index" :style="{ width: '100%' }">
            <div :style="{ display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }">
              <div :style="{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                <span>Lv.{{ item.level }}</span>
              </div>
              <div :style="{ display: 'flex', flexDirection: 'column' }">
                <span :style="{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '0.8rem' }">누적
                  {{
                    item.kdia_charge.toLocaleString() }}KDIA, 관리자 승인후 레벨업</span>
                <span :style="{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: '0.8rem' }">1일
                  {{ item.posting_max
                  }} 포스팅, {{ item.mining_value.toLocaleString() }}TIPS 채굴</span>
              </div>
            </div>
          </div>
        </div>

        <div @click="setLevelModalShow"
          :style="{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '320px', background: '#032D98', borderRadius: '4px', padding: '5px 20px', margin: '5px 0' }">
          <div :style="{ display: 'flex', flexDirection: 'column' }">
            <span :style="{ color: '#fff', fontWeight: 600, fontSize: '16px' }">KDIA 입금 신청</span>
            <span :style="{ color: '#95ACE8', fontWeight: 500, fontSize: '12px' }">외부지갑 연결하여 관리자에게 전송(트론링크 등)</span>
          </div>
          <span :style="{ color: '#fff', fontWeight: 800, fontSize: '20px' }"><i class="ri-arrow-right-s-line"></i></span>
        </div>
        <span @click="goPath('user.kdiaDList')"
          :style="{ color: '#000', fontWeight: 500, fontSize: '14px', cursor: 'pointer', textDecoration: 'underline' }">
          KDIA 입금 내역보기
        </span>
      </iq-card> -->

    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import auth from '../../../services/auth'
import StakingModal from '../../../components/core/modal/1stakingModal.vue'
import wallet from '../../../services/wallet/wallet'
import Decimal from 'decimal.js'
import KilpQRModal from '../../../components/core/modal/kilpQRModal.vue'
import SelectWalletModal from '../../../components/core/modal/selectWalletModal.vue'
import PurchaseModal from '../../../components/core/modal/purchaseModal.vue'
import PurchaseInShopModal from '../../../components/core/modal/purchaseInShopModal.vue'
import LevelModal from '../../../components/core/modal/levelModal.vue'
import RefModal from '../../../components/core/modal/refModal.vue'
import LevelInfoModal from '../../../components/core/modal/levelInfoModal.vue'
import WithdrawModal from '../../../components/core/modal/withdrawModal.vue'
import WithdrawConnectModal from '../../../components/core/modal/withdrawConnectModal.vue'
import MMSDK from '../../../services/wallet/metamask'
import kaikasSDK from '../../../services/wallet/kaikas'
import kilpSDK from '../../../services/wallet/klip'
import client from '../../../services'

export default {
  name: 'Info',
  components: {
    StakingModal,
    KilpQRModal,
    SelectWalletModal,
    PurchaseModal,
    PurchaseInShopModal,
    WithdrawModal,
    WithdrawConnectModal,
    LevelModal,
    LevelInfoModal,
    RefModal
  },
  mounted () {
    this.getMyStaking()
    this.totalFee()
    this.getMining()
    this.getMyRef()
  },
  data () {
    return {
      myTotalFee: 0,
      tipsCoin: require('../../../assets/ktips.png'),
      userCancel: false,
      walletCancel: false,
      isNameOpen: false,
      isReferralOpen: false,
      isReferralShow: false,
      isPasswordOpen: false,
      sendImage: null,
      image: null,
      name: '',
      password: '',
      checkPassword: '',
      referral: '',
      stakingModalShow: false,
      stakingOpen: false,
      purchaseInShopModalShow: false,
      levelModalShow: false,
      levelInfoModalShow: false,
      refModalShow: false,
      withdrawModalShow: false,
      withdrawConnectModalShow: false,
      ownCoin: 0,
      ableCoin: 0,
      stakingCoin: 0,
      started: '',
      expired: '',
      // 지갑 연결
      tokens:
      {
        from: 'KLAY',
        symbol: 'TIPS',
        explan: 'Korea Tips Coin',
        ratio: '0.5',
        network: 'Klaytn',
        contract: '0x981AeB68B7A9d1B3d9341636D0f45660995C6Af5',
        stockExchange: '준비중',
        doMetaMask: () => this.metamask(),
        doKaikas: () => this.kaikas(),
        doKlip: () => this.klip()
      },
      selectModalShow: false,
      modalShow: false,
      klipModalShow: false,
      selectedItem: {},
      type: '',
      wallet: '',
      refItems: [],
      mining: {
        month: 0,
        referral: 0,
        total: 0
      }
    }
  },
  methods: {
    goPath (to) {
      this.$router.push({ name: to })
    },
    async metamask () {
      const connect = await MMSDK.connect(this.setAddress, this.setAmount, this.updateWalletStaking)
      if (connect) {
        if (this.type === 'withdraw') {
          this.setWithdrawConnectModalShow()
          return
        }
        this.setModal()
      }
    },
    async kaikas () {
      const connect = kaikasSDK.connect(this.$isMobile(), this.setModal, this.setAddress, this.setAmount)
      if (connect) {
        if (this.type === 'withdraw') {
          this.setWithdrawConnectModalShow()
          return
        }
        this.setModal()
      }
    },
    async klip () {
      await kilpSDK.connect('next', this.$isMobile(), this.setKlipQR, this.setKlipModal, this.setWalletModal, this.setModal, this.setAddress, this.setAmount, this.type, this.setWithdrawConnectModalShow)
    },
    // 모달 제어
    setWithdrawConnectModalShow () {
      this.withdrawConnectModalShow = !this.withdrawConnectModalShow
    },
    // 모달 제어
    setWithdrawModalShow () {
      this.withdrawModalShow = !this.withdrawModalShow
    },
    // 모달 제어
    setPurchaseInShopModalShow () {
      this.purchaseInShopModalShow = !this.purchaseInShopModalShow
    },
    // 모달 제어
    setLevelModalShow () {
      this.levelModalShow = !this.levelModalShow
    },
    // 모달 제어
    setLevelInfoModalShow () {
      this.levelInfoModalShow = !this.levelInfoModalShow
    },
    // 모달 제어
    setRefModalShow () {
      this.refModalShow = !this.refModalShow
    },
    // 모달 제어
    setWalletModal (type, item, actionType) {
      if (type) {
        if (actionType === 'charging') {
          if (this.userState.user_id === 0) {
            alert('로그인이 필요한 서비스입니다.')
            return
          }
        }
        this.selectedItem = item ?? this.selectedItem
        this.type = actionType ?? this.type
      }
      this.selectModalShow = type
    },
    setWallet (temp) {
      this.wallet = temp
    },
    // 모달 제어
    async setModal (type) {
      if (type) {
        this.modalShow = type
        return
      }
      this.modalShow = !this.modalShow
    },
    // 모달 제어
    async setKlipModal (type) {
      // if (type) {
      this.klipModalShow = type
      // return
      // }
      // this.klipModalShow = !this.klipModalShow
    },

    async action () {
      switch (this.wallet) {
        case 'Metamask':
          this.selectedItem.doMetaMask()
          break
        case 'Kaikas':
          this.selectedItem.doKaikas()
          break
        case 'Klip':
          this.selectedItem.doKlip()
          break
      }
    },
    ...mapActions({
      setStaking: 'Auth/setStaking',
      updateCoin: 'Auth/doCheering',
      updateWalletStaking: 'Staking/updateWalletStaking',
      setId: 'WalletConnect/setId',
      setAddress: 'WalletConnect/setAddress',
      setKlipQR: 'WalletList/setKlipQR',
      setAmount: 'WalletConnect/setAmount',
      setProvider: 'WalletConnect/setProvider',
      getWalletList: 'WalletList/getWalletList',
      pushWallet: 'WalletList/pushWallet'
    }),
    // 모달 제어
    setStakingModal () {
      alert('스테이킹 신청 기간이 아닙니다.')
      // this.stakingModalShow = !this.stakingModalShow
    },
    stakingCancel () {
      // TODO
      if (!this.userCancel) {
        wallet.stakingCancel(this.stakingState[0].staking_id).then((res) => {
          if (res.data.resultCode === 'false') {
            alert('서버 오류')
            return
          }
          this.doCancel = false
          this.showStaking(false)
          this.setStaking({})
          this.updateWalletStaking([])
          alert('스테이킹이 취소되었습니다.')
        })
      } else {
        alert('처리중 입니다.\n잠시만 기다려주세요.')
      }
    },
    showStaking (type) {
      // const decimalStakingCoin = new Decimal(this.stakingState[0].start_amount)
      // const decimalCoin = new Decimal(this.userState.coin)
      if (type === 'first') {
        this.stakingCoin = new Decimal(this.stakingState[0].start_amount)
        this.ableCoin = this.userState.coin
        this.ownCoin = Decimal.add(new Decimal(this.userState.coin), new Decimal(this.stakingState[0].start_amount))
        this.expired = this.stakingState[0].expire_date
        this.started = this.stakingState[0].created_date
        this.stakingOpen = true
      } else if (type) {
        this.stakingCoin = new Decimal(this.stakingState[0].start_amount)
        this.ableCoin = Decimal.sub(new Decimal(this.userState.coin), new Decimal(this.stakingState[0].start_amount))
        this.ownCoin = Decimal.add(this.ableCoin, new Decimal(this.stakingState[0].start_amount))
        this.expired = this.stakingState[0].expire_date
        this.started = this.stakingState[0].created_date
        this.updateCoin(this.ownCoin)
        this.stakingOpen = true
      } else {
        this.stakingCoin = 0
        this.ableCoin = this.userState.coin
        this.updateCoin(this.ownCoin)
        this.ownCoin = this.userState.coin
        this.expired = ''
        this.started = ''
        this.stakingOpen = false
      }
    },
    getMyStaking () {
      wallet.getStakingList().then(res => {
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          return
        }
        this.setStaking(res.data.staking)
        if (res.data.staking.length !== 0) {
          this.showStaking('first')
        }
      })
    },
    editNameOpen () {
      this.name = this.userState.name
      this.isNameOpen = !this.isNameOpen
    },
    editNameDo () {
      auth.setInfo(this.name).then(res => {
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          return
        }
        this.userState.name = this.name
        this.isNameOpen = false
        alert('이름 변경이 완료되었습니다.')
      })
    },
    editPasswordOpen () {
      this.password = ''
      this.checkPassword = ''
      this.isPasswordOpen = !this.isPasswordOpen
    },
    editPasswordDo () {
      if (this.password !== this.checkPassword) {
        alert('동일한 비밀번호를 입력해주세요.')
        return
      }
      auth.setPassword(this.password).then(res => {
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          return
        }
        this.isPasswordOpen = false
        alert('비밀번호 변경이 완료되었습니다.')
      })
    },
    editImageDo () {
      if (this.sendImage === null) return
      auth.setImage(this.sendImage).then(res => {
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          return
        }
        this.isPasswordOpen = false
        alert('이미지 변경이 완료되었습니다.')
      })
    },
    selectedImage (e) {
      const file = e.target.files[0]
      this.sendImage = file
      this.image = (URL.createObjectURL(file))
    },
    setReferralOpen () {
      if (this.userState.referral === null) {
        this.referral = ''
        this.isReferralOpen = !this.isReferralOpen
      } else {
        this.isReferralShow = !this.isReferralShow
      }
    },
    setReferralDo () {
      if (this.referral === '') {
        alert('추천인 이메일을 입력해주세요.')
        return
      }
      if (this.referral === this.userState.account) {
        alert('본인을 추천할 수 없습니다.')
        this.referral = ''
        return
      }
      auth.setReferral(this.referral).then(res => {
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          return
        }
        if (res.data.message === 'not exist') {
          alert('존재하지 않은 이메일입니다.\n정확한 이메일을 입력해주세요.')
          this.referral = ''
          return
        }

        this.userState.referral = { toEmail: this.referral }
        this.isReferralOpen = false
        alert('추천인 등록이 완료되었습니다.')
      })
    },
    totalFee () {
      wallet.getTotalFeeList().then(res => {
        this.myTotalFee = res.data.value
      })
    },
    getMining () {
      auth.mining().then(res => {
        this.mining = res.data
      })
    },
    getMyRef () {
      auth.getMyRef().then(res => {
        this.refItems = res.data.list
      })
    },
    handleCopy () {
      this.$copyText(`https://tipsweb3.com/auth/sign-up?referral=${this.userState.account}`).then(() => {
        alert('추천인링크가 복사되었습니다.')
      })
    }
  },
  watch: {
    userState (newVal) {
      this.ableCoin = newVal.coin
      if (this.stakingState.length !== 0) {
        this.ownCoin = Decimal.add(new Decimal(this.userState.coin), new Decimal(this.stakingState[0].start_amount))
      }
      this.image = `${client.userImage}${newVal.image}`
    },
    image (newVal) {
      this.image = newVal
    }
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      stakingState: 'Auth/stakingState',
      walletState: 'WalletConnect/walletState',
      klipQRState: 'WalletList/klipQRState',
      levelState: 'Setting/levelState',
      youtuberState: 'Setting/youtuberState'
    })
  }
}
</script>
