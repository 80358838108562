<template>
  <div>
    <b-modal id="withdrawModal"
             v-model="modalShow"
             @hide="statChange"
             footer-class="justify-content-center"
             size="md"
             no-close-on-backdrop
             no-close-on-esc
             centered
             scrollable>

      <template #modal-header="{ close }">
        <h5 class="modal-title">출금하기</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

        <p>출금할 주소, TIPS를 입력해주세요.</p>
        <template>
        <b-form class="comment-text d-flex align-items-center" action="javascript:void(0);">
          <b-form-input :placeholder="'출금할 지갑주소를 입력해주세요.'" v-model="address" class="rounded" />
        </b-form>
        <b-form class="comment-text d-flex align-items-center" action="javascript:void(0);">
          <b-form-input :placeholder=" '출금할 TIPS를 입력해주세요.'" type="number" v-model="matic" class="rounded" />
        </b-form>
      </template>
      <template #modal-footer="{ cancel, ok }">
        <b-button size="sm" variant="danger" @click="cancelPurchase(cancel)">취소</b-button>
        <b-button size="sm" variant="success" @click="doPurchase(ok)">확인</b-button>
      </template>

    </b-modal>
  </div>
</template>

<script>

import wallet from '../../../services/wallet/wallet'
import { mapActions, mapGetters } from 'vuex'
import Decimal from 'decimal.js'

export default {
  name: 'withdrawModal',
  props: [
    'value'
  ],
  data () {
    return {
      modalShow: this.value,
      matic: '',
      polyt: '0',
      address: '',
      reward: this.total_reward
    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      walletState: 'WalletConnect/walletState',
      providerState: 'WalletConnect/providerState'
    })
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  },
  methods: {
    async doPurchase (ok) {
      if (this.address === '') {
        alert('출금할 주소를 입력해주세요.')
        return
      }
      if (this.matic === '') {
        alert('출금할 TIPS를 입력해주세요.')
        return
      }
      const withdraw = new Decimal(this.matic)
      const own = new Decimal(this.userState.coin)
      if (withdraw.comparedTo(own) === 1) {
        alert('사용가능한 양보다 많은 출금은 하실수 없습니다.')
        return
      }

      wallet.askingWithdraw(this.address, this.matic)
      this.address = ''
      this.matic = ''
      alert('출금요청이 완료되었습니다.')
      ok()
    },
    ...mapActions({
      updateCoin: 'Auth/doCheering',
      setAmount: 'WalletConnect/setAmount',
      setKlipQR: 'WalletList/setKlipQR'
    }),
    statChange () {
      this.$emit('statChange')
    },
    cancelPurchase (cancel) {
      this.matic = ''
      this.polyt = ''
      this.address = ''
      cancel()
    }
  }
}
</script>
