import client from './index'

export default {
  getCode (email) {
    const postData = {
      email: email
    }

    return client.json.post('/email', postData)
  },
  checkCode (email, code) {
    const postData = {
      email: email,
      code: code.toUpperCase()
    }
    return client.json.post('/email/check', postData)
  },

  login (userData) {
    return client.json.post('/login', userData)
  },
  register (userData) {
    return client.json.post('/register', userData)
  },
  register_v2 (userData, tokenData) {
    return client.json.post('/register/v2', userData, { headers: { 'register-token': tokenData } })
  },
  myInfo () {
    return client.json.get('/myinfo')
  },

  async checkPassEmail (email) {
    const postData = {
      email: email
    }
    const res = await client.json.post('/email/password', postData)
    return res.data
  },

  async checkPassCode (email, code) {
    const postData = {
      email: email,
      code: code.toUpperCase()
    }
    const res = await client.json.post('/email/password/check', postData)
    return res.data
  },

  async changePassword (token, password) {
    const postData = {
      password: password
    }
    const res = await client.json.post('/email/password/change', postData, { headers: { 'password-token': token } })
    return res.data
  },

  /**
   * 추천인 입력(프로필)
   * @param {string} toEmail 추천인 이메일
   */
  async setReferral (toEmail) {
    const postData = {
      toEmail: toEmail
    }
    return client.json.post('/setting/referral', postData)
  },

  /**
   * 추천인 입력(프로필)
   * @param {String} email
   */
  async findRef (email) {
    const postData = {
      email: email
    }
    try {
      return client.json.post('/email/confirm', postData)
    } catch (err) {
      // console.log('<POST> auth.setPassword')
      // console.log(err)
    }
  },

  /**
   * 내 채굴량 확인
   */
  async mining () {
    try {
      return client.json.get('/myinfo/mining')
    } catch (err) {
      // console.log('<POST> auth.setPassword')
      // console.log(err)
    }
  },

  /**
   * 하위 레퍼럴 목록
   */
  async getMyRef () {
    try {
      return client.json.get('/myinfo/referral')
    } catch (err) {
      // console.log('<POST> auth.setPassword')
      // console.log(err)
    }
  },

  /**
   * 이름 변경
   * @param {string} name 변경할 이름
   */
  async setInfo (name) {
    const postData = {
      name: name
    }
    try {
      return client.json.post('/setting/info', postData)
    } catch (err) {
      // console.log('<POST> auth.setInfo')
      // console.log(err)
    }
  },

  /**
   * 비밀번호 변경
   * @param {string} password 변경할 비밀번호
   */
  async setPassword (password) {
    const postData = {
      change_pw: password
    }
    try {
      return client.json.post('/setting/password', postData)
    } catch (err) {
      // console.log('<POST> auth.setPassword')
      // console.log(err)
    }
  },

  /**
   * 이름 변경
   * @param {File} image 이미지
   */
  async setImage (image) {
    const formData = new FormData()
    formData.append('IMAGE', image)
    try {
      return client.formData.post('/setting/image', formData)
    } catch (err) {
      // console.log('<POST> auth.setInfo')
      // console.log(err)
    }
  }

}
