<template>
  <div>
    <b-row>
      <b-col lg="12" class="profile-center">
        <tab-content id="pills-tabContent-2">
          <SetWidth>
          <Info />
        </SetWidth>
        </tab-content>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { core } from '../../config/pluginInit'
import { mapActions, mapGetters } from 'vuex'
import Info from './Components/Info.vue'
import SetWidth from '../../components/setWidth.vue'

export default {
  name: 'Profile',
  components: {
    Info,
    SetWidth
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      coinTitle: '(응원받은 코인 / 일별)',
      coinData: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#f87979',
            data: []
          }
        ]
      },
      postedTitle: '(내가 쓴 글 / 일별)',
      postedData: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#f87979',
            data: []
          }
        ]
      },
      modalShow: false
    }
  },
  methods: {
    ...mapActions({
      changeName: 'Auth/changeName'
    })
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      walletState: 'WalletConnect/walletState'
    })
  }
}
</script>
